import { z } from 'zod';
import { emojiSchema } from '@wndr/common/shared/features/emoji/emoji';

import { MessageReaction } from '../models/chat/chat-message';

/** Reactions attribute validation schema. */
export const reactionsAttributeSchema = z.object({
	/** Emoji identifier. */
	emoji: emojiSchema,

	/** Users identifiers who reacted. */
	users: z.array(z.string()),
});

/** Reactions attribute. */
export type ReactionsAttribute = Readonly<z.infer<typeof reactionsAttributeSchema>>;

export namespace ReactionsAttribute {

	/**
		* Transform model to reactions attribute.
		* @param reaction Chat message's reaction.
		*/
	export function fromMessageReaction(reaction: MessageReaction): ReactionsAttribute {
		return {
			emoji: reaction.emoji,
			users: [...reaction.users],
		};
	}
}
