import { z } from 'zod';

/** Emoji validation schema. */
export const emojiSchema = z.object({
	/** ID. */
	id: z.string(),

	/**
	 * Native emoji.
	 * Example: '🤞🏿'.
	 */
	native: z.string(),
});

/** Represents emoji object. */
export type Emoji = Readonly<z.infer<typeof emojiSchema>>;
