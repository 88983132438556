/* eslint-disable @typescript-eslint/naming-convention */

import { JSONValue } from '@twilio/conversations';

/** Gift attributes of twilio Message object. */
export type GiftAttributes = Readonly<{

	/** Attachment type. */
	is_incoming_gift: true;

	/** Gift info. */
	user_gift: {

		/** Gift id. */
		id: number;

		/** User name. */
		from_user: string;

		/** Note. */
		note: string;
	};

	/** Information about gifts. */
	line_items: Array<Readonly<{

		/** Gift name. */
		gift_name: string;

		/** Gift icon. */
		gift_icon: string | null;
	}>>;
}>;

/**
 * Type guard for GiftAttributes.
 * @param attributes Attributes.
 */
export function isGiftAttrubutes(attributes: JSONValue): attributes is (GiftAttributes | Array<GiftAttributes>) {
	let attribute = attributes;
	if (Array.isArray(attributes)) {
		if (attributes.length === 0) {
			return false;
		}
		attribute = attributes[0];
	}
	return 'is_incoming_gift' in (attribute as unknown as GiftAttributes);
}
