import { Message } from '@twilio/conversations';

import { isGiftAttrubutes } from '../../twilio/gift-attributes';

import { ChatMessage } from './chat-message';

/** Chat message with Gift. */
export type ChatGiftMessage = ChatMessage & Readonly<{

	/** Gift. */
	gift: {

		/** Sender name. */
		senderName: string;

		/** Gift ID. */
		id: number;

		/** Note. */
		note: string;

		/** Image URL. */
		imageUrl: string;
	};
}>;

export namespace ChatGiftMessage {

	/**
	 * Map from chat message to chat gift message.
	 * @param message Chat message.
	 */
	export function fromMessage(message: Message): ChatGiftMessage {

		const giftAttributes = isGiftAttrubutes(message.attributes) ? message.attributes : [];

		const giftDto = Array.isArray(giftAttributes) ?
			giftAttributes[0] :
			giftAttributes;

		if (giftDto) {

			const firstGiftWithImage = giftDto.line_items.find(lineItem => lineItem.gift_icon !== null);

			return {
				...ChatMessage.fromMessage(message),
				gift: {
					senderName: giftDto.user_gift.from_user,
					id: giftDto.user_gift.id,
					note: giftDto.user_gift.note,
					imageUrl: firstGiftWithImage?.gift_icon ?? '',
				},
			};
		}
		throw new Error('Invalid message type.');
	}

	/**
	 * Type guard for ChatGiftMessage.
	 * @param chatMessage ChatMessage instance.
	 */
	export function isChatGiftMessage(chatMessage: ChatMessage): chatMessage is ChatGiftMessage {
		return 'gift' in (chatMessage as unknown as ChatGiftMessage);
	}
}
