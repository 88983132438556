import { Message } from '@twilio/conversations';
import { defer, map, Observable } from 'rxjs';
import { Emoji } from '@wndr/common/shared/features/emoji/emoji';

import { ContentType } from '../content-type';
import { assertNonNullWithReturn } from '../../utils/assert-non-null';
import { isMessageAttributes } from '../../twilio/message-attributes';

/** Media message attachment. */
export type MediaAttachment = Readonly<{

	/** Attachment URL. */
	url: Observable<string>;

	/** Content type. */
	type: ContentType;

	/** Name. */
	name: string;
}>;

/** Chat message's reaction. */
export type MessageReaction = Readonly<{

	/** Emoji. */
	emoji: Emoji;

	/** Users identifiers who reacted. */
	users: ReadonlyArray<string>;
}>;

/** Chat message. */
export type ChatMessage = Readonly<{

	/** Message instance. */
	message: Message;

	/** Updated date. */
	updatedDate: Date | null;

	/** Body. */
	body: string;

	/** Author ID. */
	authorId: string;

	/** Attachments URLs. */
	attachments: ReadonlyArray<MediaAttachment>;

	/** ID. */
	id: number;

	/** Reactions. */
	reactions: ReadonlyArray<MessageReaction>;

	/** SID of the message being replied to. */
	replyToSid?: string;
}>;

export namespace ChatMessage {

	/**
	 * Map from twilio message instance to domain.
	 * @param message Twilio message instance.
	 */
	export function fromMessage(message: Message): ChatMessage {
		const messageAttributes = isMessageAttributes(message.attributes) ? message.attributes : undefined;

		return {
			message,
			authorId: assertNonNullWithReturn(message.author),
			body: message.body ?? '',
			id: message.index,
			updatedDate: message.dateUpdated,
			attachments: message.attachedMedia ?
				message.attachedMedia.map(m => ({
					type: m.contentType as ContentType,
					name: m.filename ?? '',
					url: defer(() => m.getContentTemporaryUrl()).pipe(map(value => value ?? '')),
				})) :
				[],
			reactions: messageAttributes?.reactions ?? [],
			replyToSid: messageAttributes?.replyTo,
		};
	}
}
